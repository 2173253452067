.App {
  text-align: center;
  background-color: #252A30;
  font-family: sans-serif !important;
  a {
    color: inherit;
    text-decoration: none;
  }
  .lyric-grid {
    img {
      max-width:100%;
      max-height:100%;
    }
  }
  .footer-grid{
    height: 80px;
    color: #fff;
    font-size: 20px;
    background-color: #212d3b;
    .footer-button-container {
      padding: 6px;
      .footer-button {
        height: 100%;
        color: #212d3b;
        background-color: #49525c;
        .button {
          height: 100%;
          line-height: 68px;
        }
        &:hover {
          color: #49525c;
          background-color: #212d3b;
          border: solid 1px #49525c;
        }
      }
    }
  }
}
